* {
    box-sizing: border-box;
}

:root {
    --grey-darken-1: #757575;
    --grey-lighten-3: #eeeeee;
    --grey-lighten-5: #fafafa;
    --text-black: #000;
    --color-primary: #1976d2;
    --custom-secondary: #424242;
    --shades-white: #fff;
    --black-006: rgba(0, 0, 0, 0.6);
    --black-054: #000;
    --black-087: rgba(0, 0, 0, 0.87);
    --default-boxShadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 1px 0px rgba(0, 0, 0, 0.2);
    --default-borderRadius: '4px';
}
a {
    color: var(--custom-secondary);
}

html {
    margin: 0;
    overflow-y: auto;
}

body {
    margin: 0;
    background-color: var(--grey-lighten-5);
    overflow-x: hidden;
    overflow-y: auto;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-right: 0 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.w-100 {
    width: 100%;
}

.fullPage {
    width: 100%;
    height: 100%;
    background: rgba(251, 140, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: var(--custom-secondary);
    border-style: dashed;
    color: var(--custom-secondary);
    outline: none;
    transition: border 0.24s ease-in-out;
    border-radius: 4px;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 300px;
    background: rgba(251, 140, 0, 0.1);
}

p#validatePasswordToDelete-helper-text {
    margin-left: 0;
}

.MuiDivider-light {
    color: white;
}

.MuiDivider-light::before {
    background-color: white;
}

.MuiDivider-light::after {
    background-color: white;
}

.MuiFormLabel-asterisk {
    color: red;
}

.MuiFormLabel-root {
    color: var(--custom-secondary);
}

.mr-1 {
    margin-right: 0.5rem;
}
.p-2 {
    padding: 0.2rem;
}
.ml-2 {
    margin-left: 2rem;
}
.image {
    width: 100%;
    height: auto;
}

.mt-1 {
    margin-top: 1rem !important;
}

.fit-content {
    width: fit-content !important;
}

.MuiTooltip-tooltip {
    font-size: 13px !important;
    color: #815b0e !important;
    padding: 7px 15px !important;
    background-color: #ffe38f !important;
}

.MuiTooltip-tooltip span:before {
    color: #ffe38f !important;
}

.highlight {
    filter: hue-rotate(140deg);
}

.carousel {
    height: auto;
}

.carousel-nav-buttons {
    background-color: rgba(255, 255, 255, 0.3) !important;
    border-radius: 0 !important;
}

/* Calendar */

.fc-daygrid-event {
    overflow: hidden;
}
.fc .fc-popover {
    z-index: 1;
}

.fc-timegrid-col-events {
    max-width: 95%;
}

.fc-col-header th {
    border-bottom-width: 1px;
}

.fc-daygrid-day {
    height: 120px;
}

.fc-daygrid-day-top {
    justify-content: center;
    margin-bottom: 0;
}

tbody {
    background-color: #fff;
}

@media (max-width: 600px) {
    .club-admin {
        margin-top: 35% !important;
    }

    .fc-toolbar-title {
        font-size: 16px !important;
    }

    .player {
        margin-top: 10% !important;
    }
    .alert {
        width: 100%;
    }
    .MuiDialogActions-root {
        flex-direction: column;
        gap: 8px;
        margin-left: 0;
    }

    .MuiDialogActions-root > :not(:first-of-type) {
        margin-left: 0 !important;
    }

    .fc-daygrid-day-frame {
        padding: 0.12rem !important;
    }
}

@media screen and (max-width: 425px) {
    .fc-header-toolbar,
    .fc-toolbar {
        flex-direction: column-reverse;
        gap: 16px;
    }
}

@media (max-width: 600px) {
    .club-admin {
        margin-top: 35% !important;
    }

    .fc-toolbar-title {
        font-size: 16px !important;
    }

    .player {
        margin-top: 10% !important;
    }
}
